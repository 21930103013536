export const globalConstants = {
    "cryptoSecretKey" : "b3BlbnNzaC1rZXktdjEAAAAACmFlczI1Ni1jdHIAAAAGYmNyeXB0AAAAGAAAABDa+qpU+TMZ9sGFOejIysHVAAAAEAAAAAEAAAGXAAAAB3NzaC1yc2EAAAADAQABAAABgQDPpq10R6+N",
    youthFootballStatus: {
        "noInProgress-activeDBS" : "Your DBS is compliant with FA regulations and you are eligible to be involved in youth football",
        "noInProgress-expiringSoonDBS" : "Your DBS is compliant with FA regulations, but please be aware your DBS will be due for renewal shortly",
        "noInProgress-expiredDBS": "Your DBS has now expired and must be renewed if you remain involved in youth football. Contact your Club Welfare Officer or refer to your local County FA DSO for further advice",
        "inProgress-activeDBS": "Your DBS is compliant with FA regulations and you are eligible to be involved in youth football. Once completed, your new DBS Application will update the existing DBS",
        "inProgress-expiringSoonDBS": "Your DBS is compliant with FA regulations and you are eligible to be involved in youth football. Once completed, your new DBS Application will update the existing DBS",
        "inProgress-expiredDBS": `Your New DBS Application to work with children is “In Progress” and has yet to be accepted. If your Existing DBS has expired (or expires whilst your New DBS remains “In Progress”), contact your club(s), as they must perform the necessary risk assessments and ensure appropriate supervision is in place (in line with The FA’s guidance on ratios of adults to children). Contact your Club’s Welfare Officer or refer to your local County FA DSO for further advice`,
        "inProgress-noDBS": "You are not eligible to be involved in youth football until your DBS check has been accepted",
        "noInProgress-suspendedDBS":"You are suspended from any involvement in youth football",
        "inProgress-suspendedDBS": "You are suspended from any involvement in youth football. This may change in the future, dependent on the outcome of your in progress DBS Application",
        "noInProgress-referredToCMDBS": "You are not eligible to be involved in youth football until your DBS check has been accepted",
        "noInProgress-noDBS": "You are not eligible to be involved in youth football",
        "completed-dbs":"Your DBS is compliant with FA regulations and you are eligible to be involved in youth football or any football activity where there may be players under the age of 18.",
        "withdrawn-dbs":"You are not eligible to be involved in youth football or any football activity where there may be players under the age of 18.",
        "awaitingAction-awaitingCertificateRetrieval":"You are not eligible to be involved in youth football or any football activity where there may be players under the age of 18.",
        "awaitingAction-noCertificateRetrieved":"You are suspended from any involvement in youth football or any football activity where there may be players under the age of 18.",
        "awaitingAction-certificateRetrieved": "You are not eligible to be involved in youth football (or any football activity where there may be players under the age of 18)  until your DBS check has been accepted",
        "awaitingAction-referredToFa": "You are not eligible to be involved in youth football  (or any football activity where there may be players under the age of 18) until your DBS check has been accepted",
        "indeterminate-dbs": "You are not eligible to be involved in youth football or any football activity where there may be players under the age of 18."
    },
    dbsSuspendedError: "User <b>suspended</b> from any involvement in youth football",
    loginHelpBootstrapUrl: "https://widget.freshworks.com/widgetBase/bootstrap.js",
    preferencesUrl: "https://preferences.thefa.com/preference/index.html"
}